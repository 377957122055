// Home.js
import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import WebcamCapture from './WebcamCapture';
import Detector from "./Detector";

const Home = ({ session }) => {
  const [displayedImage, setDisplayedImage] = useState(null);
  const [showWebcamCapture, setShowWebcamCapture] = useState(false);
  const [showDetector, setShowDetector] = useState(false);

  const handleCapture = (image) => {
    setDisplayedImage(image);
    setShowWebcamCapture(false);
    setShowDetector(true);
  };

  const handleFeedClick = () => {
    setShowWebcamCapture(true);
    setShowDetector(false);
    setDisplayedImage(null);
  };

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="flex flex-grow items-center justify-center">
        <div>
            {!showDetector && !showWebcamCapture && (
            <Header onFeedClick={handleFeedClick} />
          )}
          {showWebcamCapture && (
            <WebcamCapture onCapture={handleCapture} />
          )}
          {showDetector && displayedImage && (
            <>
              <Detector session={session} image={displayedImage} />
              <div className = "w-full text-center">
                <button onClick={() => {
                  setShowDetector(false);
                  setShowWebcamCapture(false);
                }} className="mt-5 text-lg bg-red-500 hover:bg-red-700 rounded-full text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out">
                  Back
                </button>
              </div>

            </>
          )}
          </div>
        </div>
      <Footer/>
    </div>
  );
};

export default Home;
