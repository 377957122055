import React, { useState, useEffect } from 'react';
import { detectItems } from "../utils/detect"; // Adjust path as necessary
import TextRender from './TextRender';

const Detector = ({ image, session }) => {
  const [loading, setLoading] = useState(true); // Start with loading true until detection completes
  const [results, setResults] = useState(null); // Use null to distinguish uninitialized state
  const [munching, setMunching] = useState(false);

  useEffect(() => {
    if (!image || !session) return;

    // Reset states for a new detection
    setResults(null); // Reset results to null to avoid showing incorrect message
    setLoading(true);
    setMunching(false);

    const runDetection = async () => {
      try {
        const items = await detectItems(image, session, 100, 0.5, 0.3, [1, 3, 640, 640]);
        const filteredResults = items.filter(item => item.probability >= 0.5);
        setResults(filteredResults); // Update with new results
        saveDetectionResults(filteredResults);
      } catch (error) {
        console.error('Error during detection:', error);
        setResults([]); // Assume no results on error
      } finally {
        setLoading(false); // End loading state
        // Start munching state with a cooldown period
        setMunching(true);
        setTimeout(() => {
          setMunching(false);
        }, 10000); // Adjust cooldown as necessary
      }
    };

    runDetection();
  }, [image, session]); 

  const saveDetectionResults = (items) => {
    const currentTime = new Date().toISOString();
    const existingData = JSON.parse(localStorage.getItem('detectionStats')) || [];
    const newData = {
      time: currentTime,
      items: items,
    };
    existingData.push(newData);
    localStorage.setItem('detectionStats', JSON.stringify(existingData));
  };

  // Handling conditional rendering based on state
  if (loading) {
    return <div>Loading results...</div>;
  } else if (results && results.length > 0) {
    return (
      <div className="w-full mt-4">
        <h2 className="text-6xl font-bold text-center">
          You fed the hole <TextRender items={results.reduce((acc, { label }) => {
            acc[label] = (acc[label] || 0) + 1;
            return acc;
          }, {})} />.
        </h2>
      </div>
    );
  } else if (results && results.length === 0) {
    return <div className="text-6xl font-bold text-center">You fed the hole something unknown.</div>;
  } else if (munching) {
    return <div>The hole is still munching...</div>;
  }

  // If the component has not yet initialized results, don't show any message
  return null;
};

export default Detector;
