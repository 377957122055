const Footer = () => {
    return (
      <footer className="w-full text-center mt-10 py-2">
        <a 
          href="https://gulan28.com" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-400 hover:text-gray-300"
        >
          A gulan28 product 🃏
        </a>
      </footer>
    );
  };
  
  export default Footer;
  