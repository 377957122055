// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Stats from './components/Stats';
import Loader from "./components/Loader";
import ModelLoader from './components/ModelLoader';
import "./style/App.css";

const App = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  return (
    <Router>
      {loading ? (
        <>
          <Loader text="Waking up the hole..." />
          <ModelLoader setLoading={setLoading} setSession={setSession} /> 
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Home session={session} />} />
          <Route path="/stats" element={<Stats />} />
          {/* You can add more routes here as needed */}
        </Routes>
      )}
    </Router>
  );
};

export default App;
