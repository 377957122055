import React, { useState, useEffect } from 'react';
import labels from '../utils/labels.json'; // Adjust the path as necessary
import pluralize from 'pluralize';

const LabelRotator = () => {
  const [currentLabel, setCurrentLabel] = useState();
  const [color, setColor] = useState('text-black');

  // Tailwind CSS color classes
  const colors = [
    'text-red-500', 'text-green-500', 'text-blue-500', 
    'text-yellow-500', 'text-purple-500', 'text-pink-500'
  ];

  useEffect(() => {
    const updateLabelAndColor = () => {
      // Select a random label
      const randomLabel = labels[Math.floor(Math.random() * labels.length)];
      // Select a random color
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      setCurrentLabel(pluralize(randomLabel));
      setColor(randomColor);
    };

    // Update label and color every 1 second
    const intervalId = setInterval(updateLabelAndColor, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-white mt-4 font-bold text-xl">
      Feed the hole with <span className={`${color}`}>{currentLabel}</span>
    </div>
  );
};

export default LabelRotator;
