const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-5 max-w-sm w-full">
          <div className="flex justify-between items-center">
            <span className="text-red-500 text-xl">⚠️</span>
            <button onClick={onClose} className="text-lg">✖️</button>
          </div>
          <p className="mt-2 text-black">{message}</p>
        </div>
      </div>
    );
  };

export default ErrorPopup;
