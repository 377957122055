// ModelLoader.js
import React, { useEffect } from "react";
import cv from "@techstark/opencv-js";
import { InferenceSession, Tensor } from "onnxruntime-web";
import { download } from "../utils/download";

const ModelLoader = ({ setLoading, setSession }) => {
  useEffect(() => {
    const loadModels = async () => {
      cv["onRuntimeInitialized"] = async () => {
        const baseModelURL = `${process.env.PUBLIC_URL}/model`;
        const modelName = "yolov8n.onnx";
        const modelInputShape = [1, 3, 640, 640];
        
        setLoading({ text: "Loading YOLOv8 Segmentation model", progress: null });
        const arrBufNet = await download(`${baseModelURL}/${modelName}`);
        const yolov8 = await InferenceSession.create(arrBufNet);
        
        setLoading({ text: "Loading NMS model", progress: null });
        const arrBufNMS = await download(`${baseModelURL}/nms-yolov8.onnx`);
        const nms = await InferenceSession.create(arrBufNMS);
        
        setLoading({ text: "Warming up model...", progress: null });
        const tensor = new Tensor(
          "float32",
          new Float32Array(modelInputShape.reduce((a, b) => a * b)),
          modelInputShape
        );
        await yolov8.run({ images: tensor });
        
        setSession({ net: yolov8, nms: nms });
        setLoading(false); // Update to false to indicate loading is complete
      };
    };

    loadModels();
  }, [setLoading, setSession]);

  return null; // Optionally, return a loading indicator here if needed
};

export default ModelLoader;