import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import TextRender from './TextRender'; // Adjust the import path as needed
import pluralize from 'pluralize';
import Footer from "./Footer";

const Stats = () => {
  const stats = JSON.parse(localStorage.getItem('detectionStats')) || [];
  let content;

  if (stats.length === 0) {
    content = "You haven't fed the hole anything.";
  } else {
    content = "You fed the hole";
  }

  const totalItems = stats.reduce((acc, { items }) => {
    items.forEach(item => {
      const label = item.label;
      acc[label] = (acc[label] || 0) + 1;
    });
    return acc;
  }, {});

  const itemsByDay = stats.reduce((acc, { time, items }) => {
    const day = format(parseISO(time), 'yyyy-MM-dd');
    items.forEach(item => {
      const label = item.label;
      if (!acc[day]) acc[day] = {};
      acc[day][label] = (acc[day][label] || 0) + 1;
    });
    return acc;
  }, {});

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="flex flex-grow justify-center items-center">
        <div>
          <h1 className="text-6xl font-bold text-center lg:mt-20 mt-10">
            {content} {stats.length > 0 && <TextRender items={totalItems} />}
          </h1>
          {/* Back button */}
          <div className="w-full flex justify-center">
            <Link to="/" 
              className="mt-8 rounded-full text-lg bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded transition duration-150 ease-in-out"
            >
              Back
            </Link>
          </div>          
          {/* Conditionally render the list of items by day if there are stats */}
          {stats.length > 0 && Object.entries(itemsByDay).map(([day, items]) => (
            <div key={day} className="mt-10 text-center">
              <h3 className="text-xl font-semibold">{format(parseISO(day), 'PPP')}</h3>
              <ul>
                {Object.entries(items).map(([label, count]) => (
                  <li key={label} className="text-2xl font-bold">
                    {`${count} ${pluralize(label, count)}`}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Stats;
