import React, { useState, useEffect, useRef } from 'react';
import ErrorPopup from './ErrorPopup';

const WebcamCapture = ({ onCapture }) => {
  const videoRef = useRef(null);
  const [devices, setDevices] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState(0);
  const [isCapturing, setIsCapturing] = useState(true);
  const [error, setError] = useState(''); // State to hold error messages
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          setDevice(videoDevices[0].deviceId); // Automatically select the first device initially
        }
      } catch (error) {
        console.error("Error listing devices", error);
        setError("Failed to list video devices."); // Set error message
        setShowErrorPopup(true); // Show the error popup
      }
    };

    getDevices();
  }, []);

  const setDevice = async (deviceId) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing the webcam", error);
      setError("Failed to access the webcam."); // Set error message
      setShowErrorPopup(true); // Show the error popup
    }
  };

  useEffect(() => {
    if (devices.length > 0) {
      const deviceId = devices[deviceIndex % devices.length].deviceId;
      setDevice(deviceId);
    }
  }, [deviceIndex, devices]);

  const captureImage = async () => {
    // Check if the video stream is available
    if (videoRef.current && videoRef.current.srcObject) {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        
        canvas.toBlob(blob => {
            if (blob) {
                const image = new Image();
                image.onload = () => { 
                    onCapture(image);
                    URL.revokeObjectURL(image.src); // Clean up
                };
                image.src = URL.createObjectURL(blob);
            } else {
                console.error("Failed to capture image");
                setError("Failed to capture image."); // Set error message
                setShowErrorPopup(true); // Show the error popup
            }
        }, 'image/jpeg');
        
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null;      
        setIsCapturing(false);
    } else {
        // Handle the case where there's no video stream
        setError("Webcam access denied or not available.");
        console.error("Webcam access denied or not available.");
        setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the popup
    setError(''); // Optionally reset the error message if appropriate
  };

  const changeCamera = () => {
    setDeviceIndex(prevDeviceIndex => prevDeviceIndex + 1);
  };

  return (
    <div className="w-full h-screen relative">
      {isCapturing && (
        <>
          <video ref={videoRef} autoPlay playsInline className="w-full h-full"></video>  
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4">
            <button onClick={changeCamera} className="text-base rounded-full bg-gray-100 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 my-5 border border-gray-400 rounded shadow">
              Change Camera
            </button>
          </div>

          <div className="absolute left-1/2 bottom-36 transform -translate-x-1/2">
            <button onClick={captureImage} className="w-20 h-20 bg-white rounded-full flex items-center justify-center text-3xl shadow-lg cursor-pointer">
              📷
            </button>
          </div>         
        </>
      )}
      {showErrorPopup && <ErrorPopup message={error} onClose={handleCloseErrorPopup} />}
    </div>
  );
};

export default WebcamCapture;
