import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LabelRotator from './LabelRotator';
const TIMETOFEED = 1.01;

const Header = ({ onFeedClick }) => {
  const [disableFeed, setDisableFeed] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const detectionStats = JSON.parse(localStorage.getItem('detectionStats')) || [];
    if (detectionStats.length > 0) {
      const latestDetection = detectionStats[detectionStats.length - 1];
      const latestTime = new Date(latestDetection.time);
      const currentTime = new Date();
      const diffMs = currentTime - latestTime; // difference in milliseconds
      const diffMins = diffMs / (1000 * 60); // convert milliseconds to minutes
      
      if (diffMins < TIMETOFEED) {
        setDisableFeed(true);
        // Start countdown
        const remainingTime = TIMETOFEED * 60 - (diffMs / 1000); // remaining time in seconds
        setCountdown(Math.ceil(remainingTime));
        
        const interval = setInterval(() => {
          setCountdown((currentCountdown) => {
            if (currentCountdown <= 1) {
              clearInterval(interval);
              setDisableFeed(false);
              return 0;
            }
            return currentCountdown - 1;
          });
        }, 1000);
        
        return () => clearInterval(interval);
      }
    }
  }, []);

  return (
    <div className="text-center mt-8">
      <h1 className="text-8xl font-bold mb-4">FEED THE HOLE</h1>
      <LabelRotator />
      <button
        onClick={!disableFeed ? onFeedClick : undefined}
        className={`text-lg pulse-button w-24 h-24 ${!disableFeed ? 'bg-white hover:bg-red-600 text-black' : 'bg-gray-400 text-white'} font-bold my-10 transition duration-150 ease-in-out`}
      >
        {disableFeed ? `${Math.floor(countdown / 60)}:${('0' + (countdown % 60)).slice(-2)}` : 'feed'}
      </button>
      <div className="flex justify-center mt-10 items-center flex-col">
        <Link to="/stats" className="text-lg underline">
          Stats
        </Link>
      </div>
    </div>
  );
};

export default Header;
