import React from 'react';
import pluralize from 'pluralize';

// Define Tailwind CSS color classes within TextRender for encapsulation
const tailwindColors = [
  'text-red-500', 'text-green-500', 'text-blue-500', 
  'text-yellow-500', 'text-purple-500', 'text-pink-500', 
  'text-indigo-500', 'text-gray-500', 'text-orange-500', 
  'text-teal-500', 'text-cyan-500', 'text-lime-500', 
  'text-emerald-500', 'text-fuchsia-500', 'text-rose-500', 
  'text-violet-500', 'text-sky-500', 'text-amber-500',
];

const getColorForLabel = (label) => {
  let sumChars = 0;
  for (let i = 0; i < label.length; i++) {
    sumChars += label.charCodeAt(i);
  }
  return tailwindColors[sumChars % tailwindColors.length];
};

const TextRender = ({ items }) => {
  const itemList = Object.entries(items).map(([label, count]) => (
    <span className={`${getColorForLabel(label)}`} key={label}>
      {`${count} ${pluralize(label, count)}`}
    </span>
  ));

  const sentence = itemList.reduce((acc, elem, idx, arr) => {
    return acc.concat(
      idx === arr.length - 1 && idx !== 0 ? [' and ', elem] :
      idx < arr.length - 1 && idx !== 0 ? [', ', elem] : [elem]
    );
  }, []);

  return <>{sentence}</>;
};

export default TextRender;
