import React from "react";
import "../style/loader.css";

const Loader = (props) => {
  return (
    <div className="wrapper" {...props}>
      <div className="w-4 h-4 pulse-loader mx-4"></div>
      <p>{props.text}</p>
    </div>
  );
};

export default Loader;
